<section class="sectionGap faq" id="faq">
    <div class="container">     
        <h2 class="section-title mx-auto text-center" *ngIf="!isMobile">
            <span class="text-secondary me-2">{{"faq.title_1" | translate }}</span>
            <span class="text-white">{{"faq.title_2" | translate }}</span>
        </h2> 
        <h2 class="font-40 fw-bold section-title" *ngIf="isMobile">{{"faq.mob_head" | translate }}</h2>
        
        <div class="row d-flex align-items-center justify-content-center">          
            <div class="col-12 col-lg-10">            
                <div class="faq-wrapper pt-4">
                    <accordion [closeOthers]="true" [isAnimated]="true">
                        <accordion-group *ngFor="let item of items;let i = index;">
                            <div class="  position-relative w-100 fw-medium d-flex justify-content-between"
                                accordion-heading>
                                <span class=" text-white font-14 font-sm-18">
                                    {{item.heading | translate}}
                                </span>
                                <img src="/assets/images/svg-icons/angle-down.svg" class="icon pt-2" alt="arrow" height="19"/>
                            </div>
                            <div accordion-panel class="mt-0">
                                <span class="para fw-regular"> {{item.content | translate}}</span><br />
                            </div>
                        </accordion-group>
                        <accordion-group>
                            <div class=" position-relative w-100 fw-medium d-flex justify-content-between"
                                accordion-heading>
                                <span class=" text-white font-13 font-sm-18">
                                    {{ 'faq.faq_5' | translate}}
                                </span>
                                <img src="/assets/images/svg-icons/angle-down.svg" class="icon pt-2" alt="arrow" height="19" />
                            </div>
                            <div accordion-panel class="mt-0">
                                <div class="para fw-regular">
                                    {{ 'faq.faq_cont_5' | translate}}
                                    <a href="https://x.com/BTCBULL_TOKEN" target="_blank">X (Twitter)</a>
                                    {{ 'faq.faq_cont_5_1' | translate}}
                                    <a href="https://t.me/BTCBULLTOKEN" target="_blank">Telegram</a>
                                    {{ 'faq.faq_cont_5_2' | translate}}
                                </div>
                            </div>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
        </div>
    </div>
</section>