import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor() { }

  // error toast message
  messsageAlert(icon: SweetAlertIcon, text: string) {
    let backgroundClass;
    if(icon === 'error') {
      backgroundClass = 'bg-danger';
    } else if (icon === 'success') {
      backgroundClass = 'bg-success';
    } else if (icon === 'info') {
      backgroundClass = 'bg-info';
    } else if (icon === 'warning') {
      backgroundClass = 'bg-warning';
    } else{
      backgroundClass = 'bg-light';
    }
    const options: SweetAlertOptions = {
      position: 'bottom-end',
      timer: 4000,
      toast: true,
      timerProgressBar: true,
      showConfirmButton: false,
      iconColor: '#fff',
      icon: icon,
      text: text,
      customClass: {
        popup: 'd-flex align-items-center rounded-sm ' + backgroundClass,
        htmlContainer: 'fw-semibold fs-5 text-white',
      }
    };
    return Swal.fire(options);
  }

  // message popup
  messagePopup(icon: SweetAlertIcon, text: string, titleText?: string, confirmBtnTxt?: string, denyBtnTxt?: string, cancelBtnTxt?: string) {
    const options: SweetAlertOptions = {
      iconHtml: '<img src="./assets/images/popup-icons/'+icon+'.svg" alt="'+icon+'" height="90">',
      titleText: titleText ? titleText : undefined,
      text: text,
      showDenyButton: denyBtnTxt ? true : false,
      showCancelButton: cancelBtnTxt ? true : false,
      confirmButtonText: confirmBtnTxt ? confirmBtnTxt : 'OK',
      denyButtonText: denyBtnTxt,
      cancelButtonText: cancelBtnTxt,
      buttonsStyling: false,
      customClass: {
        icon: 'border-0',
        popup: 'rounded-4 shadow-md',
        title: 'fs-2 fw-semibold pt-4',
        htmlContainer: 'fw-medium fs-5 m-0 my-3',
        confirmButton: 'btn btn-sm text-white rounded-1 border-0',
        denyButton: 'btn btn-sm btn-warning',
        cancelButton: 'btn btn-sm btn-warning'
      }
    };
    return Swal.fire(options);
  }

  close() {
    return Swal.close();
  }
}
