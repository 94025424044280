import {  AfterViewInit, Component, ElementRef, HostListener, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { languages } from '../../shared/constants/app-enums';
import { EventService } from '../../services/event.service';
import { WINDOW } from '../../services/window';
import { isPlatformBrowser } from '@angular/common';
// import { getWindow } from 'ssr-window';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})


export class FooterComponent implements AfterViewInit {
  
  @ViewChild('navigation') navigation!: ElementRef;
  isScrolled: boolean = false;
  hashKey: string = '';
  windowSize: number = 0;

  currentLanguage: any;
  languagesList: Array<any> = languages;
  selectedLanguage: any;
  isMobile: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowSize = window.innerWidth;
    this.isMobile = this.windowSize <= 1024;
  }
  
  links = [
    {
      title: "top_navigation.nav_about",
      routeName: 'about',
    },
    {
      title: "top_navigation.nav_howtobuy",
      routeName: 'how-to-buy',
    },
    {
      title: "top_navigation.nav_roadmap",
      routeName: 'roadmap',
    },
    {
      title: "top_navigation.nav_faq",
      routeName: 'faq',
    },
  ]


  items = [
    {
      title: "footer_navigation.terms",
      link:""
    },
    {
      title: "footer_navigation.privacy",
      link:""
    },
    {
      title: "footer_navigation.cookies",
      link:""
    },
  ]

  social = [
    {
      img:"assets/images/svg-icons/social-icons/telegram.svg",
      alt:"",
      link:""
    },
    {
      img:"assets/images/svg-icons/social-icons/twitter.svg",
      alt:"",
      link:""
    },
  ]

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    @Inject(WINDOW) private window: Window,
    private eventService: EventService,
    private translate: TranslateService,
    ) {
    this.windowSize = window.innerWidth;
    this.isMobile = this.windowSize <= 1024;
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.currentLanguage = params.lang;
    this.selectedLanguage = this.languagesList.filter(x => x.value === this.currentLanguage)[0];
    });

  }


  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.windowSize = window.outerWidth;
      this.isMobile = this.windowSize <= 1024;
    }
  }

  changeLanguage(requestLang: any): void {
    this.currentLanguage = requestLang;
    this.translate.use(this.currentLanguage);
    this.eventService.setLang(this.currentLanguage);
    this.selectedLanguage = this.languagesList.filter(x => x.value === this.currentLanguage)[0];
  }

  navigateToSection(section: string) {
    this.hashKey = section;
    if (section) {
      window.location.hash = section;
      window.location.pathname = '/' + this.currentLanguage;
    }
  }
}
