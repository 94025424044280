import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Platform } from '@angular/cdk/platform';
import { WalletConnectService } from '../../services/wallet-connect.service';



@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']
})

export class ConnectComponent implements OnInit {

  @ViewChild('bestwallet', { static: false }) bwTemplate!: TemplateRef<any>;
  bwMode: boolean = false;
  isBsNetwork: boolean = false;
  isAndroid = false;
  isIos = false;

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private walletConnectService: WalletConnectService,
    private platform: Platform,
  ) {
    this.isAndroid = this.platform.ANDROID;
    this.isIos = this.platform.IOS;
  }

  ngOnInit(): void {
    if(this.bwMode){
      this.modalRef.hide();
      setTimeout(()=> {
        this.openBestWallet(this.bwTemplate);
        this.connectWallet('bw');
      },200);
    }
  }

  connectWallet(mode: string): void {
    this.walletConnectService.connectWallet(mode, this.isBsNetwork);
    if(this.modalRef) this.modalRef.hide();
  };

  openBestWallet(template: TemplateRef<any>) {
    if(this.modalRef) this.modalRef.hide();
    setTimeout(()=> {
      this.modalRef = this.modalService.show(template, {
        class: 'modal-md modal-dialog-centered',
        backdrop: 'static',
      });
    }, 500)
  }
}
