import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs';

import { verifyLanguage } from '../../shared/constants/app-enums';
import { WINDOW } from '../../services/window';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent {

  lang: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private translate: TranslateService,
  ) {

    this.translate.setDefaultLang('en');
    this.router.events.pipe(filter(e => e instanceof NavigationEnd))
    .subscribe((navEnd:NavigationEnd) => {
      // console.log('NavigationEnd', navEnd);
      let lang = navEnd.urlAfterRedirects.split('/')[1];
      // console.log('NavigationEnd filtered lang level-1 =', lang);
      lang = lang.split('#')[0];
      // console.log('NavigationEnd filtered lang level-2 =', lang);
      lang = lang.split('?')[0];
      // console.log('NavigationEnd filtered lang level-3 =', lang);
      
     if (lang && verifyLanguage(lang)) {
        this.lang = lang;
      } else if(this.window){
        if (isPlatformBrowser(this.platformId)) {
          const userLang = navigator?.language?.split('-')[0];
          this.lang = userLang && verifyLanguage(userLang) ? userLang : 'en';
        } else this.lang = 'en';
      } else this.lang = 'en';
      // console.log('current language is =',this.lang)
      this.translate.use(this.lang);
    });
  }
}
