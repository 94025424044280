<div class="p-4  connect">

  <!-- ----- Step 0 ------ -->

  <div class="d-flex justify-content-between align-items-center">
    <div></div>
    <h4 class="text-center  fw-semibold fs-5 m-0 text-white" >{{"misc.connect_wallet" | translate}}</h4>
    <svg-icon src="./assets/images/svg-icons/close-white.svg" [svgStyle]="{ 'width.px':14, 'height.px':14 }"
      class="cursor-pointer" (click)="modalRef.hide()"></svg-icon>
  </div>

  <div class="mt-4 content">

     <button type="button" class="btn btn-primary rounded-1 py-2 px-3 w-100 d-flex justify-content-between align-items-center mb-3"
      (click)="connectWallet('wallet')">
      <span class="d-inline-block" >{{"popup.wallet_connect" | translate}}</span>
      <svg-icon src="./assets/images/svg-icons/wallet.svg" [svgStyle]="{ 'width.px':32, 'height.px':32 }"></svg-icon>
    </button>


    <button type="button" class="btn btn-primary rounded-1 py-2 px-3 w-100 d-flex d-md-none justify-content-between align-items-center mb-3"
      (click)="connectWallet('bw')">
      <span class="d-inline-block">{{"misc.best_wallet" | translate}}</span>
      <img src="./assets/images/bw.png" height="32" width="32" alt="">
    </button>

    <button type="button" class="btn btn-primary rounded-1 py-2 px-3 w-100 d-md-flex d-none justify-content-between align-items-center mb-3"
    (click)="connectWallet('walletBW')">
    <span class="d-inline-block">{{"misc.best_wallet" | translate}}</span>
    <img src="./assets/images/bw.png" height="32" width="32" alt="">
  </button>

    <button type="button" class="btn btn-primary rounded-1 py-2 px-3 w-100 d-none d-md-flex justify-content-between align-items-center mb-3"
      (click)="connectWallet('metamask')">
      <span class="d-inline-block">{{"popup.metamask_connect" | translate}}</span>
      <svg-icon src="./assets/images/svg-icons/metamask.svg" [svgStyle]="{ 'width.px':32, 'height.px':32 }"></svg-icon>
    </button>

  <button type="button" class="btn btn-primary rounded-1 py-2 px-3 w-100 d-flex justify-content-between align-items-center mb-3"
    (click)="connectWallet('coinbase')">
    <span class="d-inline-block" >{{"popup.coinbase_connect" | translate}}</span>
    <svg-icon src="./assets/images/svg-icons/coinbase.svg" [svgStyle]="{ 'width.px':32, 'height.px':32 }"></svg-icon>
  </button>


     <!---------- i dont have wallet --------------->

     <a href="<presale-sing-url-need-to-be-added>" target="_blank" class="d-block d-md-none">
      <button type="button" class="btn btn-clear w-100 d-flex justify-content-center align-items-center aniBtn">
        <svg-icon src="/assets/images/svg-icons/ani-wallet.svg"
          [svgStyle]="{ 'width.px':20, 'height.px':19, 'margin-right.px':5 }"></svg-icon>
        <span class="d-inline-block text-light" >{{"popup.no_wallet" | translate}}</span>
      </button>
    </a>

    <button type="button" class="d-none btn btn-clear w-100 d-md-flex justify-content-center align-items-center aniBtn"
      (click)="openBestWallet(bestwallet)">
      <svg-icon src="/assets/images/svg-icons/ani-wallet.svg"
        [svgStyle]="{ 'width.px':20, 'height.px':19, 'margin-right.px':5 }"></svg-icon>
      <span class="d-inline-block text-light" >{{"popup.no_wallet" | translate}}</span>
    </button>
  </div>

</div>


<!-- ----- Best Wallet ------ -->

<ng-template #bestwallet>
  <div class="p-4 connect text-light">

    <div class="d-flex justify-content-between align-items-center">
      <div></div>
      <h4 class="text-center  fw-semibold fs-4 m-0">{{"popup.need_wallet" | translate}}</h4>
      <svg-icon src="/assets/images/svg-icons/close-white.svg" [svgStyle]="{ 'width.px':14, 'height.px':14 }"
        class="cursor-pointer" (click)="modalRef.hide()"></svg-icon>
    </div>

    <div class="mt-4 content d-flex flex-column align-items-center justify-content-between">

      <div class="text-center d-none d-md-block pb-2">{{"popup.install_app" | translate}}</div>
      <div class="text-center d-block d-md-none pb-2">{{"popup.install_app_mob" | translate}}</div>
      <div class="d-flex align-items-center justify-content-around">
        <div class="d-flex flex-column align-items-center mt-2">
          <div class="qr-div d-none d-md-flex flex-column align-items-center bg-white p-3">
            <img src="/assets/images/bestwallet_modal/presale_qrcode.svg" alt="qr" loading="lazy" class="qr-img" height="300" width="300">
          </div>
        </div>
      </div>
      <a href="<presale-sing-url-need-to-be-added>ng.link/Dnio2/z1mje?_smtype=3" target="_blank" class="mt-2 d-md-none w-100 d-flex align-items-center justify-content-center">
        <button type="button" class="btn btn-sm border-0 rounded-1 bg-primary py-2 px-3 w-50 d-block d-md-none">{{"popup.download_bw" | translate}}</button>
      </a>

      <div class="text-center fw-semibold my-3 fs-4">{{"popup.already_have" | translate}}</div>
      <div class="text-center mb-3">{{"popup.hit_btn_connect" | translate}}</div>
      <button type="button"
        class="btn btn-sm border-0 rounded-1 bg-primary py-2 px-3 w-50 mb-3 d-none d-md-block" (click)="connectWallet('walletBW')">
        <span class="d-inline-block text-black">{{"misc.connect_wallet" | translate}}</span>
      </button>
      <button type="button"
        class="btn btn-sm border-0 rounded-1 bg-primary py-2 px-3  w-50  mb-3 d-block d-md-none" (click)="connectWallet('bw')">
        <span class="d-inline-block text-black">{{"misc.connect_wallet" | translate}}</span>
      </button>
    </div>

  </div>
</ng-template>
