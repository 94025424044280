<div class="modal-header">
  <h4 class="modal-title text-center">{{"popup.ref_link" | translate }}</h4>
</div>
<div class="modal-body">

  <div class="fw-medium font-16 text-center">{{"popup.ref_text" | translate }}</div>

  <button type="button" class="btn btn-sm btn-outline-primary my-3 d-block refer-btn wx-250 mx-auto" (click)="copy()" ngxClipboard [cbContent]="url">
    <div class="d-flex align-items-center justify-content-center">
      <span class="d-inline-block text-truncate font-13" >{{url}}</span>
      <svg-icon src="./assets/images/svg-icons/copy.svg" [svgStyle]="{ 'width.px':20, 'height.px':20 }" class=""></svg-icon>
    </div>
  </button>

  <div class="footer">
    <button type="button" class="btn btn-sm btn-outline-primary w-100 mt-3 icon-btn d-block" (click)="modalRef.hide()">{{"misc.close" | translate }}</button>
  </div>

</div>
