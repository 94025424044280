import { Component } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WalletConnectService } from '../../services/wallet-connect.service';
import { TrimAddressPipe } from '../../shared/pipes/trim-address.pipe';

@Component({
  selector: 'app-bestwallet',
  templateUrl: './bestwallet.component.html',
  styleUrls: ['./bestwallet.component.scss',]
})

export class BestwalletComponent {

  walletAddress: any = '';

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private walletConnectService: WalletConnectService
  ) {
    const trimPipe = new TrimAddressPipe()
    this.walletAddress = trimPipe.transform(this.walletConnectService.walletAddress);
  }

}
