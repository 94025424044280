import {
  Component,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  ElementRef,
  HostListener,
  PLATFORM_ID,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';

import { TranslateService } from '@ngx-translate/core';
import { SwapStatus, convertNum } from '../../shared/constants/app-enums';
import { environment } from '../../../environments/environment';
import { ClaimComponent } from '../../modals/claim/claim.component';
import { ConnectComponent } from '../../modals/connect/connect.component';
import { PopupService } from '../../services/popup.service';
import { WalletConnectService } from '../../services/wallet-connect.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-staking',
  templateUrl: './staking.component.html',
  styleUrls: ['./staking.component.scss']
})

export class StakingComponent implements AfterViewInit {

  @ViewChild('graphCont') graphCont!: ElementRef;

  rewards = [
    {
      name: 'Aug-2024', //1
      value: 18987500000,
    },
    {
      name: 'Sep-2024', //2
      value: 19075000000,
    },
    {
      name: 'Oct-2024', //3
      value: 19162500000,
    },
    {
      name: 'Nov-2024', //4
      value: 19250000000,
    },
    {
      name: 'Dec-2024', //5
      value: 19337500000,
    },
    {
      name: 'Jan-2025', //6
      value: 19425000000,
    },
    {
      name: 'Feb-2025', //7
      value: 19512500000,
    },
    {
      name: 'Mar-2025', //8
      value: 19600000000,
    },
    {
      name: 'Apr-2025', //9
      value: 19687500000,
    },
    {
      name: 'May-2025', //10
      value: 19775000000,
    },
    {
      name: 'Jun-2025', //11
      value: 19862500000,
    },
    {
      name: 'Jul-2025', //12
      value: 19950000000,
    },
    {
      name: 'Aug-2025', //13
      value: 20037500000,
    },
    {
      name: 'Sept-2025', //14
      value: 20125000000,
    },
    {
      name: 'Oct-2025', //15
      value: 20212500000,
    },
    {
      name: 'Nov-2025', //16
      value: 20300000000,
    },
    {
      name: 'Dec-2025', //17
      value: 20387500000,
    },
    {
      name: 'Jan-2026', //18
      value: 20475000000,
    },
    {
      name: 'Feb-2026', //19
      value: 20562500000,
    },
    {
      name: 'Mar-2026', //20
      value: 20650000000,
    },
    {
      name: 'Apr-2026', //21
      value: 20737500000,
    },
    {
      name: 'May-2026', //22
      value: 20825000000,
    },
    {
      name: 'Jun-2026', //23
      value: 20912500000,
    },
    {
      name: 'Jul-2026', //24
      value: 21000000000,
    },
  ];

  fitContainer: boolean = true;
  view: [any, any] = [400, 200];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  legendTitle: string = 'Total Rewards';
  legendPosition: string = 'below'; // ['right', 'below']
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Months';
  showYAxisLabel = true;
  yAxisLabel = 'Supply';
  SwapStatus = SwapStatus;
  showGridLines: boolean = true;
  showDataLabel: boolean = false;
  pageLoaded: boolean = false;
  maxYAxis = 100;
  minYAxis = 0;
  stakeUnlockTime = new Date(environment.stakeUnlockTime);
  stakeAmount!: number;
  colorScheme: Color = {
    name: 'myScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: [
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
      '#87878780',
    ],
  };

  lang: any = '';
  timeInterval: any;
  cryptoName: string = environment.tokenName;

  walletAddress: any;
  stakeData = this.walletConnectService.stakeData
  userStaked: any = 0;
  poolPercent: any = 0;
  userReward: any = 0;
  ethClaimable: any = 0;
  ethTokens: any = 0;
  totalStaked: any = 0;
  userBal: any = 0;
  unstakeDate: any;
  canUnstake: any;
  hash: any;
  status:any;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.view = [+this.graphCont.nativeElement.offsetWidth / 1.05, 400];
  }

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private popupService: PopupService,
    private translate: TranslateService,
    private walletConnectService: WalletConnectService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private cdRef: ChangeDetectorRef
  ) {
    let diff = 0;
    const date = new Date();
    const today = date.getUTCDate();
    const currMon = date.getUTCMonth();
    const currYear = date.getUTCFullYear();

    this.rewards.forEach((item: any, index: number) => {
      const itemDate = new Date(item.name);
      itemDate.setDate(itemDate.getDate() + 10);
      const itemMon = itemDate.getUTCMonth();
      const itemYear = itemDate.getUTCFullYear();
      if (itemYear === currYear && itemMon === currMon) {
        diff = index;
      }
    });

    this.colorScheme.domain[diff] = '#FFC700';
    if (isPlatformBrowser(this._platformId)) setInterval(() => this.updateAllgetMethod(), 500);
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    if (isPlatformBrowser(this._platformId)) {
      this.pageLoaded = true;
      setTimeout(()=> this.view = [+this.graphCont.nativeElement.offsetWidth / 1.05, 400], 500);
      this.cdRef.detectChanges();
    }
  }


  updateAllgetMethod(): void {
    this.walletAddress = this.walletConnectService.walletAddress;
    this.stakeData = this.walletConnectService.stakeData;
    this.userStaked = this.walletAddress  ? this.formatNumber(+this.stakeData.userStaked.toFixed(0)) : 0;
    this.poolPercent = this.walletAddress && this.stakeData.totalStaked !== 0 ? +((this.stakeData.userStaked * 100) / this.stakeData.totalStaked).toFixed(5) || 0 : 0;
    this.userReward = this.walletAddress ? this.formatNumber(+this.stakeData.userReward.toFixed(0)) : 0;
    this.ethClaimable = this.walletAddress ? this.formatNumber(+this.walletConnectService.balanceData.ethClaimable!.toFixed(0)) : 0;
    this.ethTokens = this.walletAddress ? this.walletConnectService.balanceData.ethClaimable : 0;
    this.totalStaked = this.formatNumber(+this.stakeData.totalStaked.toFixed(0));
    this.userBal = this.formatNumber(this.stakeData.userBal);
    this.unstakeDate = new Date(this.stakeData.unlockTime * 1000);
    this.unstakeDate = this.unstakeDate > this.stakeUnlockTime ? this.unstakeDate : this.stakeUnlockTime;
    this.canUnstake =  this.unstakeDate < new Date();
    this.hash = this.walletConnectService.swapHash;
    this.status = this.walletConnectService.swapStatus;
  }


  checkEthChain() {
    if (!this.walletAddress) {
      this.connectModal();
      return false;
    }
    if (this.walletConnectService.isEthChain()) {
      return true;
    } else {
      this.popupService
        .messagePopup('info', this.translate.instant('popup.wrong_chain', { chainName: environment.eth.chainName,}), this.translate.instant('popup.change_network'))
        .then((res) => {
          if (res.isConfirmed) {
            this.walletConnectService.switchNetwork(environment.eth.chainIdInt);
          }
        });
      return false;
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-xs modal-dialog-centered',
      backdrop: 'static',
    });
  }

  buyClaimStake(template: TemplateRef<any>): void {
    if (isPlatformBrowser(this._platformId)) {
      if (!this.checkEthChain()) {
        this.timeInterval = setInterval(() => this.startConnectInterval(template), 2000);
      } else if (this.walletAddress && this.ethClaimable == 0) {
        this.openModal(template);
      }
    }
  }

  startConnectInterval(template: TemplateRef<any>) {
    if (this.walletAddress && this.walletConnectService.isEthChain()) {
      this.openModal(template);
      clearInterval(this.timeInterval);
    }
  }

  claimStake(): void {
    if (this.ethClaimable == 0 || !this.checkEthChain()) return;
    this.modalRef = this.modalService.show(ClaimComponent, {
      class: 'modal-xs modal-dialog-centered',
      backdrop: 'static',
      initialState: { isStake: true },
    });
    this.walletConnectService.claimStake();
  }

  closeModal() {
    this.modalRef!.hide();
  }

  public yAxisTickFormattingFn = this.yAxisTickFormatting.bind(this);

  yAxisTickFormatting(value: any) {
    return this.formatNumber(value / 1000000000) + 'M'; // this is where you can change the formatting
  }

  onSelect(event: any) {
    console.log(event);
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  formatString(input: string): string {
    return input.toUpperCase();
  }

  formatNumber(input: number) {
    return convertNum(input);
  }

  
  withdrawStake(): void {
    if (isPlatformBrowser(this._platformId)) {
      if (this.stakeData.userStaked == 0 || !this.checkEthChain()) return;
      this.modalRef!.hide();
      setTimeout(() => {
        this.modalRef = this.modalService.show(ClaimComponent, {
          class: 'modal-xs modal-dialog-centered',
          backdrop: 'static',
          initialState: { isWithdraw: true },
        });
      }, 500);
      this.walletConnectService.withdrawStake();
    }
  }

  claim(): void {
    if (isPlatformBrowser(this._platformId)) {
      if (!this.stakeData.isClaimEnabled) {
        this.popupService.messagePopup('info', this.translate.instant('popup.claim_not_live'));
        return;
      }
      if (this.stakeData.userReward == 0 || !this.checkEthChain()) return;
      this.walletConnectService.claimStakeRewards();
      this.modalRef?.hide();
      setTimeout(() => {
        this.modalRef = this.modalService.show(ClaimComponent, {
          class: 'modal-xs modal-dialog-centered',
          backdrop: 'static',
        });
      }, 500);
    }
  }

  max() {
    this.stakeAmount = this.stakeData.userBal;
  }

  stakeToken(template: TemplateRef<any>): void {
    if (isPlatformBrowser(this._platformId)) {
      if (!this.checkEthChain() || this.stakeAmount > this.stakeData.userBal) return;
      this.walletConnectService.createStake(this.stakeAmount);
      this.modalRef!.hide();
      setTimeout(() => {
        this.modalRef = this.modalService.show(template, {
          class: 'modal-xs modal-dialog-centered',
          backdrop: 'static',
        });
      }, 1000);
    }
  }

  connectModal(): void {
    this.modalRef = this.modalService.show(ConnectComponent, {
      class: 'modal-min modal-dialog-centered',
      backdrop: 'static',
      initialState: { isBsNetwork: false },
    });
  }

  poolInfo() {
    this.popupService.messagePopup('info', this.translate.instant('staking.pool_share'));
  }

  viewUrl() {
    if (isPlatformBrowser(this._platformId)) {
      if (typeof window == 'undefined') return;
      const blkUrl = environment.eth.blockExplorerURL;
      window.open(blkUrl + this.hash, '_blank');
    }
  }
}
