import { ApplicationRef, Component, Inject, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { getParam } from './shared/constants/app-enums';
import { EventService } from './services/event.service';
import { DOCUMENT } from '@angular/common';

declare global {
  interface Window {
    dataLayer: any[];
  }
}
export var selectedLang: string;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  canRender: boolean = true;

  constructor(
    @Inject(DOCUMENT) private dom: any,
    private meta: Meta,
    private title: Title,
    private eventService: EventService,
    private translate: TranslateService,
  ) {

    inject(ApplicationRef).isStable.subscribe(isStable => this.canRender = isStable || this.canRender);

    getParam('clickId');
    getParam('source');
    getParam('pid');
    getParam('tid');
    let base = 'https://ico-boilerplate.dev/';


    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      selectedLang = params.lang;
      const metaData = this.translate.instant('misc.meta_desc');
      const metaTitle = this.translate.instant('misc.meta_title');
      // console.log('metaData =', metaData)
      this.title.setTitle(metaTitle);
      this.meta.updateTag({ name: 'description', content: metaData });

      const link: HTMLLinkElement | null = this.dom.querySelector('link[rel=canonical]');
      if(selectedLang == 'en') {
        if (link) {
          link.href = base;
        } else {
          const newLink = this.dom.createElement('link');
          newLink.rel = 'canonical';
          newLink.href = base;
          this.dom.head.appendChild(newLink);
        }
      } else {
        if (link) {
          link.href = base+selectedLang+'/';
        } else {
          const newLink = this.dom.createElement('link');
          newLink.rel = 'canonical';
          newLink.href = base+selectedLang+'/';
          this.dom.head.appendChild(newLink);
        }
      }
    });
  }
}
