
export const environment = {
  production: false,

  eth: {
    chainId: '0xaa36a7', //hexadecimal for metamask
    chainIdInt: 11155111,
    chainName: 'Ethereum Mainnet',
    gasFees: 0,
    purchaseToken: 'ETH',
    hasStaking: true,
    nativeFunction: 'buyWithEth',
    presaleAddress: '0x23EA5D894e000aaca6EA7D2fD07a07a80Cf7F768',
    usdTAddress: '0x9fa2AE3c0a571BbBFd17E74d7470b6c444A87aF2',
    rpcURL: '',
    backupRPC: '',
    blockExplorerURL: 'https://etherscan.io/tx/',
    chainInfo: {
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0xaa36a7',
          chainName: 'Ethereum Mainnet',
          nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://rpc.ankr.com/eth'],
          blockExplorerUrls: ['https://etherscan.io/'],
        },
      ],
    },
  },

  bsc: {
    chainId: '0x61', //hexadecimal for metamask
    chainIdInt: 97,
    chainName: 'BSC Mainnet',
    gasFees: 0,
    purchaseToken: 'BNB',
    hasStaking: false,
    nativeFunction: 'buyWithBNB',
    presaleAddress: '0x29366e4F643A521f7874F4c4cFf88Aa8c4643A1e',
    usdTAddress: '0xADFc9B54FB7a1677176A7529F03b91081C91355c',
    rpcURL: '',
    backupRPC: '',
    blockExplorerURL: 'https://bscscan.com/tx/',
    chainInfo: {
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: '0x61',
        chainName: 'BSC Mainnet',
        nativeCurrency: {
          name: 'BNB',
          symbol: 'BNB',
          decimals: 18,
        },
        rpcUrls: ['https://bsc-dataseed2.ninicoin.io/'],
        blockExplorerUrls: ['https://bscscan.com/'],
      }]
    }
  },

  minToken:         0,
  tokenAddress:     '0xbe7036bf1a60c545faef671137f89db6d94be4d0',
  stakingAddress:   '0xE907183A1F9250efe279bc2Dd149357EbBA123D2',
  apyCalcConst:     '416666667',
  stakeUnlockTime: '1912943476000',
  tokenName:        '$TALC',
  walletConnectId:  'dcc6d18b5082c57305a72c6ea1026e01',
  iidDashFx:        0,
  urlDashFx:        '',
  wertOrigin:       'https://sandbox.wert.io',                                                    // testnet
  partnerId:        '01HK57C8XMFEVRTMAW55NBD9W2',                                                 // testnet
  wertUrl:          'https://ijs2jkwmnzohnnjppqkypl2avq0hhlcm.lambda-url.eu-west-2.on.aws/',      // testnet
  referUrl:         '',
  bwUniversalLink:  'https://best-wallet-dev.web.app',
  bwDeepLink:       'bw-dev://app/connect?',
  bwApiUrl:         'https://test-api.bestwallet.com/presale-transactions',
  showBestWallet:   false,
  domain: 'localhost:3000'
};
