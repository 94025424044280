export enum SwapStatus {
  not_started = 0,
  in_progess = 1,
  complete = 2,
  failed = 3,
  rejected = 4,
  approval_pending = 5,
  confirm_pending = 6
}

export const languages = [
  // {
  //   name: "Arabic",
  //   value: "ar",
  //   flag: "ar",
  // },
  // {
  //   name: "Bulgarian",
  //   value: "bg",
  //   flag: "bg",
  // },
  {
    name: "English",
    value: "en",
    flag: "en"
  },
  // {
  //   name: "Chinese (simplified)",
  //   value: "zh-hans",
  //   flag: "cn"
  // },
  {
    name: "Chinese (traditional) ",
    value: "zh-hant",
    flag: "cn"
  },
  {
    name: "Czech",
    value: "cz",
    flag: "cz"
  },
  {
    name: "Dutch",
    value: "nl",
    flag: "nl"
  },
  // {
  //   name: "French",
  //   value: "fr",
  //   flag: "fr"
  // },
  // {
  //   name: "German",
  //   value: "de",
  //   flag: "de"
  // },
  // {
  //   name: "Greek",
  //   value: "el",
  //   flag: "el"
  // },
  // {
  //   name: "Hungarian",
  //   value: "hu",
  //   flag: "hu"
  // },
  // {
  //   name: "Indonesian",
  //   value: "id",
  //   flag: "id"
  // },
  // {
  //   name: "Italian",
  //   value: "it",
  //   flag: "it"
  // },
  // {
  //   name: "Japanese",
  //   value: "jp",
  //   flag: "jp"
  // },
  // {
  //   name: "Korean",
  //   value: "kr",
  //   flag: "kr"
  // },
  // // {
  // //   name: "Persian",
  // //   value: "fa",
  // //   flag: "fa"
  // // },
  // {
  //   name: "Polish",
  //   value: "pl",
  //   flag: "pl"
  // },
  // {
  //   name: "Portuguese",
  //   value: "pt",
  //   flag: "pt"
  // },
  // {
  //   name: "Romanian",
  //   value: "ro",
  //   flag: "ro"
  // },
  // {
  //   name: "Russian",
  //   value: "ru",
  //   flag: "ru"
  // },
  // {
  //   name: "Slovak",
  //   value: "sk",
  //   flag: "sk"
  // },
  // {
  //   name: "Spanish",
  //   value: "es",
  //   flag: "es"
  // },
  // {
  //   name: "Thai",
  //   value: "th",
  //   flag: "th"
  // },
  // {
  //   name: "Turkish",
  //   value: "tr",
  //   flag: "tr"
  // },
  // {
  //   name: "Vietnamese",
  //   value: "vn",
  //   flag: "vn"
  // },
];

export enum CTAStatus {
  none,
  eth,
  usdt,
  transak
}

export function getParam(paramName: string) {
  if (typeof window == 'undefined')
    return;
  let getCookie = (name: string) => {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  };

  let urlParams = new URLSearchParams(document.location.search);
  let param: any = getCookie(paramName);

  if (urlParams.get(paramName)) { // always take clickId from URL if present
    param = urlParams.get(paramName);
    document.cookie = paramName + "=" + param + ";max-age=15778462";
  }

  return param ? param : "";
}

export function getParamWithoutCookie(paramName: string) {
  if (typeof window == 'undefined')
    return;
  let urlParams = new URLSearchParams(document.location.search);
  let param;
  if (urlParams.get(paramName)) { // always take clickId from URL if present
    param = urlParams.get(paramName);
  }

  return param ? param : "";
}

export function getBrowserName() {
  const agent = window.navigator.userAgent.toLowerCase()
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'edge';
    case agent.indexOf('opr') > -1 && !!(<any>window).opr:
      return 'opera';
    case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
      return 'chrome';
    case agent.indexOf('trident') > -1:
      return 'ie';
    case agent.indexOf('firefox') > -1:
      return 'firefox';
    case agent.indexOf('safari') > -1:
      return 'safari';
    default:
      return 'other';
  }
}

export function verifyLanguage(lang: string): boolean {
  const validLang = languages.filter(x => x.value === lang);
  return validLang.length > 0 ? true : false;
}

export function convertNum(value: number, decimals = 2) {
  const num = Math.floor(value * Math.pow(10, decimals))/(Math.pow(10, decimals));
  let nf = new Intl.NumberFormat('en-US');
  nf.format(num); // "1,234,567,890"
  return nf.format(num);
}

export function convertDecimal(value: number, decimals: number = 2) {
  return Math.floor(value * Math.pow(10, decimals))/(Math.pow(10, decimals));
}
