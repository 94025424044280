<!-- -------- desktop header --------- -->
<div class="header" [ngClass]="{'scrolled': isScrolled}" *ngIf="!isMobile">

  <div class="d-flex justify-content-between align-items-center w-100 menu-header">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center w-100">

        <a class="cursor-pointer d-flex align-items-center p-0" routerLink="/{{currentLanguage}}/" #navigation (click)="navigateToSection('home'); addActive($event, '')">
          <svg-icon src="/assets/images/svg-icons/logo.svg" [svgStyle]="{ 'width.px':'152','height.px':'53' }"></svg-icon>
        </a>

        <div class="menu-item d-flex align-items-center">
          <a routerLink="/{{currentLanguage}}/staking"  (click)="addActive($event,'')" title="staking">{{"top_navigation.nav_staking" | translate}}</a>

          <a class="mx-2" routerLink="/{{currentLanguage}}" fragment="{{item.routeName}}" (click)="addActive($event, item.routeName)" title="{{item.routeName}}"
           *ngFor="let item of navigations">{{item.navName | translate}}
          </a>

          <!-- ---------- Social network --------- -->
          <span class="ms-3">
            <a *ngFor="let item of social" href="{{item.link}}" class="link m-0" target="_blank">
              <img src="{{item.img}}" alt="{{item.alt}}" loading="lazy">
            </a>
          </span>

          <!--- buynow wallet/address --->
          <div class="btn btn-primary d-flex align-items-center font-16 ms-4 text-uppercase" (click)="showWidget('home')"  *ngIf="!walletAddress">{{"widget.buy_now" | translate}}</div>
          <div class="btn btn-primary d-flex align-items-center font-16 ms-4" (click)="disconnect()" *ngIf="walletAddress">{{walletAddress | trimAddress}}</div>


          <!---language dropdown-->

          <div class="btn-group language ms-4" dropdown>
            <div dropdownToggle type="button" class="dropdown-toggle amountType d-flex align-items-center">
              <div class="font-12 text-uppercase fw-semibold">{{ selectedLanguage?.value }}</div>
              <!-- <img class="flag-icon mx-1" src="./assets/images/flags/{{selectedLanguage?.flag}}.svg" alt="" loading="lazy"> -->
              <svg-icon class="dropdown ms-2" src="./assets/images/svg-icons/angle-down.svg" [svgStyle]="{ 'width.px':'14', 'height.px':'22' }"></svg-icon>
            </div>

            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
              <li class="p-2 cursor-pointer" role="menuitem" *ngFor="let lang of languagesList" (click)="changeLanguage(lang.value)">
                <img src="./assets/images/flags/{{lang.flag}}.svg" alt="" loading="lazy">
                <span>{{ lang.name }}</span>
              </li>
            </ul>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>








<!-- -------- mobile header --------- -->

<div class="header" [ngClass]="{'scrolled': isScrolled}" *ngIf="isMobile">

  <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100">

    <div class="d-flex justify-content-start align-items-center">
      <a class="cursor-pointer p-0" routerLink="/{{currentLanguage}}/" #navigation>
        <svg-icon src="/assets/images/svg-icons/logo.svg"  [svgStyle]="{ 'width.px':'50','height.px':'50' }"></svg-icon>
      </a>
    </div>

    <div class="menu-btn" (click)="openCloseMenu()">
      <svg-icon src="./assets/images/svg-icons/{{showMenu ? 'close-white' : 'burger-icon'}}.svg" [svgStyle]="{ 'width.px':24, 'height.px':24 }"></svg-icon>
    </div>

  </div>

  <div class="menu-overlay" *ngIf="showMenu">
    <div class="sub-menu pull-left" [ngClass]="{'open': openMenu}">

      <!--- menu links-->
      <div class="d-flex flex-column justify-content-start align-items-start w-100">
        <a class="font-14 py-2 pb-2" routerLink="/{{currentLanguage}}/staking"  title="staking" (click)="openCloseMenu()" routerLinkActive="active">{{"top_navigation.nav_staking" | translate}}</a>
        <a class="mx-0 pb-1 text-whitre" *ngFor="let item of navigations" routerLink="/{{currentLanguage}}" fragment="{{item.routeName}}" (click)="addActive($event, item.routeName); openCloseMenu()" title="{{item.routeName}}">{{item.navName | translate}}
        </a>
      </div>

      <!--- social links --->

      <div class="d-flex align-items-center justify-content-center social-wrapper my-3"  >
        <a *ngFor="let item of social" href="{{item.link}}" class="link m-0" target="_blank">
          <img src="{{item.img}}" alt="{{item.alt}}" loading="lazy">
        </a>
      </div>

        <!---language dropdown-->
        <div class="btn-group language my-3" dropdown>
          <div dropdownToggle type="button" class="dropdown-toggle amountType d-flex align-items-center justify-content-between">
            <div>
              <!-- <img class="flag-icon" src="./assets/images/flags/{{selectedLanguage?.flag}}.svg" alt="" loading="lazy"> -->
              <!-- <span class="ms-1 font-11 text-uppercase fw-regular">{{ selectedLanguage?.country }}</span> -->
                <span class="ms-1 font-16 text-uppercase fw-semi-bold">{{ selectedLanguage?.value }}</span>
            </div>

            <svg-icon class="dropdown" src="./assets/images/svg-icons/down-arrow.svg" [svgStyle]="{ 'width.px':'20', 'height.px':'20' }"></svg-icon>
          </div>

          <ul *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu">
            <li class="p-2 cursor-pointer" role="menuitem" *ngFor="let lang of languagesList" (click)="changeLanguage(lang.value); openCloseMenu()">
              <img src="./assets/images/flags/{{lang.flag}}.svg" alt="" loading="lazy">
              <span>{{ lang.name }}</span>
            </li>
          </ul>
        </div>

      <div class="btn btn-secondary mt-4 mx-auto" (click)="showWidget('home'); openCloseMenu()" *ngIf="!walletAddress">{{"widget.buy_now" | translate}}</div>
      <div class="btn btn-secondary mt-4 mx-auto" (click)="disconnect(); openCloseMenu()" *ngIf="walletAddress">{{ walletAddress | trimAddress }}</div>

    </div>

  </div>
</div>
