<div class="p-4">
  <div class="d-flex justify-content-between align-items-center">
    <div>&nbsp;&nbsp;&nbsp;</div>
    <h4 class="text-center fw-semibold fs-5 m-0 text-white" *ngIf="currentStep === 0">{{"widget.buy_now" | translate}}</h4>
    <h4 class="text-center fw-semibold fs-5 m-0 text-white" *ngIf="currentStep === 1">{{"wert.title" | translate}}</h4>
    <svg-icon src="/assets/images/svg-icons/close-white.svg" [svgStyle]="{ 'width.px':14, 'height.px':14 }" class="cursor-pointer" (click)="modalRef.hide()"></svg-icon>
  </div>

  <div class="mt-4 content text-center" *ngIf="currentStep === 0">
    <p  class="text-white">{{'wert.disclaimer' | translate: {token} }}</p>
    <button (click)="showWidget()" class="btn btn-primary bg-white text-black font-14 mt-2 mx-auto">{{"wert.continue" | translate}}</button>
  </div>

  <div class="mt-4 content text-center" *ngIf="currentStep === 1">
    <img src="/assets/images/svg-icons/logo.svg" alt="loading" class="worker">
    <p translate class="text-white">{{"wert.desc" | translate}}</p>
  </div>

</div>
