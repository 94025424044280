import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent implements AfterViewInit {
  
  slideOption: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    dots: false,
    nav: false,
    autoplayTimeout: 2000,
    center:true,
    margin:10,
    responsive: {
      0: {
        items: 1.5,
        // margin:15,
      },
      400: {
        items: 4
      },
      740: {
        items: 6
      },
      940: {
        items: 6
      }

    },
  };
  items = [
    {
      img: './assets/images/featured/feature_1.svg'
    },
    {
      img: './assets/images/featured/feature_2.svg'
    },
    {
      img: './assets/images/featured/feature_3.svg'
    },
    {
      img: './assets/images/featured/feature_4.svg'
    },

  ]

  isRender: boolean = false;
  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
  ){}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId)) this.isRender = true
  }
}
