<div class="staking" id="staking">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-between ">
      <div class="col-lg-6 col-12 ">
        <h3 class="pb-3 heading m-0  pe-2 font-45 text-white fw-bold">{{"staking.title" | translate}}</h3>
        <p class="font-18 text-white">{{"staking.desc" | translate}}</p>
      </div>
      <div class="col-lg-3 col-12">
        <div class="d-flex">
          <!-- <button class="btn btn-primary font-16  w-100" (click)="openModal(withdraw)">{{"staking.withdraw_title" | translate}}</button> -->
        </div>
      </div>
      <!-- <div class="col-lg-5 col-12 justify-content-md-end d-flex"></div> -->

      <div class="col-lg-12 col-12 mt-5">
        <div class="box-wrap">

          <!-- ====== Buy and stake card ====== -->

          <div class="box">
            <div>
              <span class="title text-uppercase mb-1 d-inline-block">{{"staking.card_1_title" | translate}}</span>
              <h2 class="font-18 mt-1 mb-3 d-flex align-items-start text-white">{{userStaked}}
                <span class="d-inline-block ms-2 font-16 text-white">{{"staking.card_coin" | translate}}</span>
              </h2>
            </div>
            <div>
              <span class="title font-16 text-uppercase mb-1 d-inline-block">{{"staking.your_stakeable" | translate}} </span>
              <h2 class="font-18 mt-1 d-flex align-items-start text-white">{{ethClaimable}}
                <span class="d-inline-block ms-2 font-16 text-white">{{"staking.card_coin" | translate}}</span>
              </h2>
            </div>

            <!-- == Claim And Stake == -->
            <a class="btn btn-primary fs-6  w-100 mt-3" title="staking" *ngIf="ethTokens && ethTokens > 0" (click)="claimStake()">{{"staking.claim_stake" | translate}}</a>

            <!-- == Buy And Stake == -->
            <a class="btn btn-primary fs-6  w-100 mt-3" (click)="buyClaimStake(buy_stake)" *ngIf="ethTokens == 0">{{"staking.buy_stake" | translate}}</a>

          </div>

          <!-- ====== % OF POOL card ====== -->

          <div class="box">
            <div class="w-100">
              <div class="d-flex align-items-center justify-content-between ">
                <span class="title font-16   text-uppercase mb-1 d-inline-block">{{"staking.card_2_title" | translate}}</span>
                <img src="/assets/images/svg-icons/info-icon-white.svg" class="img-fluid cursor-pointer" alt="" (click)="poolInfo()">
              </div>
              <h2 class="font-18 mt-1 mb-3 text-white">{{poolPercent}}%</h2>
              <div>
                <span class="title font-16 text-uppercase mb-1 d-inline-block">{{"staking.total_staked" | translate}}</span>
                <h2 class="font-18 mt-1 d-flex align-items-start text-white">{{totalStaked}}
                  <span class="d-inline-block ms-2 font-16 text-white">{{"staking.card_coin" | translate}}</span>
                </h2>
              </div>
              <button class="btn btn-primary fs-6  w-100 mt-3" (click)="openModal(withdraw)">{{"staking.withdraw_title" | translate}}</button>
            </div>
          </div>

          <!-- ====== Estimated reward  card====== -->

          <div class="box justify-content-start">
            <div>
              <span class="title font-16 text-uppercase mb-1 d-inline-block">{{"staking.card_3_title" | translate}}</span>
              <h2 class="font-18 mt-2 text-white">{{stakeData.apy}}%<sup class="mt-2 font-12 fw-bold title ps-2">{{"staking.stake_pa" | translate}}</sup></h2>
            </div>
            <ul class="p-0 mt-3 mb-0">
              <li class="text-white"><img src="/assets/images/svg-icons/arrow-right-white.svg"
                  height="8px" class="me-1" alt="">{{"staking.card_3_para_1" | translate}}</li>
              <li class="text-white"><img src="/assets/images/svg-icons/arrow-right-white.svg"
                  height="8px" class="me-1" alt="">{{"staking.card_3_para_2" | translate}}</li>
              <li class="text-white"><img src="/assets/images/svg-icons/arrow-right-white.svg"
                  height="8px" class="me-1" alt="">{{"staking.card_3_para_3" | translate}}</li>
            </ul>
          </div>

          <!-- ====== Current Reward card====== -->

          <div class="box">
            <div>
              <span class="title font-16 text-uppercase mb-1 d-inline-block">{{"staking.card_4_title" | translate}}</span>
              <h2 class="font-18 mt-2 d-flex align-items-start text-white">{{stakeData.rewardPerRound}}
                <sup class="mt-2 font-12 fw-bold title ps-2">{{"staking.card_block" | translate}}</sup>
              </h2>
            </div>
          </div>

          <!-- ====== Total Reward card====== -->

          <div class="box">
            <div>
              <span class="title font-16 text-uppercase mb-1 d-inline-block">{{"staking.card_5_title" | translate}}</span>
              <h2 class="font-18 mt-2 d-flex align-items-start text-white">{{userReward}}
                <span class="d-inline-block ms-2 font-16 text-white">{{"staking.card_coin" | translate}}</span>
              </h2>
            </div>
            <button class="btn btn-primary fs-6  w-100 mt-3" (click)="claim()">{{"staking.rewards_btn" | translate}}</button>
          </div>
        </div>

      </div>
    </div>

    <!-- ====== chart wrapper ====== -->

    <div class="row mt-5 align-items-center justify-content-between">
      <div class="col-lg-9 mb-3">
        <div class="chart-wrapper">
          <div class="d-flex justify-content-start px-3 align-items-center">
            <h4 class="mt-4 mb-3 text-start text-white fw-regular">{{"staking.total_rewards" | translate}}</h4>
          </div>
          <div class="w-100 graph-parent" #graphCont *ngIf="pageLoaded">
            <ngx-charts-bar-vertical [view]="view" [scheme]="colorScheme" [results]="rewards" [gradient]="gradient"
              [xAxis]="showXAxis" [yAxis]="showYAxis" [legendTitle]="legendTitle" [legend]="showLegend"
              [yScaleMin]="minYAxis" [yScaleMax]="maxYAxis" [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [showDataLabel]="showDataLabel"
              [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [yAxisTickFormatting]="yAxisTickFormattingFn"
              (select)="onSelect($event)">
            </ngx-charts-bar-vertical>
          </div>

        </div>

        <div class="stake-text mt-2">
          <p class="font-14 mb-0">{{"staking.staking_powered_by" | translate}}
            <a href="https://web3paymentsolutions.io/" target="_blank">
              <img src="/assets/images/svg-icons/W3P_White.svg" alt="Web3Payments" class="poweredByIcon text-tertiary">
            </a>
          </p>
        </div>
      </div>


      <div class="col-lg-3  d-none d-sm-block">
        <img src="/assets/images/svg-icons/logo.svg" alt="" class="img-fluid mx-auto token d-flex align-items-center justify-content-center">

      </div>
    </div>
  </div>
</div>

<!-- ====== token withdraw modal ====== -->
<ng-template #withdraw>

  <div class="modal-body">
    <div class="d-flex align-items-center justify-content-center w-100 mb-3">
      <h4 class="modal-title pull-left text-white">{{"staking.withdraw_title" | translate}}</h4>
    </div>
    <div class="text-center my-4">
      <span class="font-14 text-uppercase mb-1 d-inline-block text-white">{{"staking.card_1_title" | translate}}</span>
      <h2 class="font-25 mt-2 d-flex align-items-start fw-bold justify-content-center text-white">{{userStaked}}
        <span class="d-inline-block text-white ms-2 font-12 fw-bold">{{"staking.card_coin" | translate}}</span>
      </h2>
      <div class="d-inline-block text-white ms-2 font-14" *ngIf="stakeData.userStaked > 0">
        {{'staking.withdraw_text' | translate:{unstakeDate: (unstakeDate | date:"dd/MM/yy HH:mm")} }}
      </div>
    </div>
    <div class="d-flex align-items-center gap-2 mt-3">
      <button class="btn btn-outline-primary text-white fs-6  w-100" (click)="closeModal()">{{"misc.cancel" | translate}}</button>
      <button class="btn btn-primary fs-6  w-100" [disabled]="!canUnstake" (click)="withdrawStake()">{{"staking.withdraw" | translate}}</button>
    </div>
  </div>

</ng-template>

<!-- ====== stake modal ====== -->
<ng-template #stake>

  <div class="modal-body">
    <div class="d-flex align-items-center justify-content-center w-100 mb-3">
      <h4 class="modal-title pull-left text-white">{{"staking.stake_token" | translate}}</h4>
      <span class="cursor-pointer stake-close" (click)="closeModal()" aria-hidden="true">X</span>
    </div>
    <div class="text-center my-4">
      <span class="staking-title font-14 text-uppercase mb-1 d-inline-block">{{"widget.user_bal" | translate}}</span>
      <h2 class="font-25 mt-2 text-black d-flex align-items-start fw-bold justify-content-center">{{userBal}}<span
          class="d-inline-block text-white ms-2 font-12 fw-bold">{{"staking.card_coin" | translate}}</span></h2>
    </div>
    <div class="form-group">
      <div class="d-flex align-items-center justify-content-between">
        <label class="font-12">{{"staking.stake_amount" | translate}}</label>
        <a class="font-12 text-decoration-underline cursor-pointer text-black" (click)="max()">{{"widget.max" | translate}}</a>
      </div>
      <input type="text" class="form-control text-black" placeholder="0" [(ngModel)]="stakeAmount">
      <p class="font-13">{{"staking.enterAmount" | translate}}</p>
    </div>
    <div class="d-flex align-items-center ">
      <button class="btn btn-outline-tertiary fs-7 w-100" (click)="closeModal()">{{"misc.cancel" | translate}}</button>
      <!-- <a href="javascript:;" class="button  bg-secondary font-16 text-white ms-3 w-100" (click)="stakeToken(successfull);">{{"widget.stake_now" | translate}}</a> -->
    </div>
  </div>

</ng-template>

<!-- ====== successful modal ====== -->
<ng-template #successfull>

  <div class="modal-body d-flex align-items-center flex-column justify-content-center">
    <div class="text-center w-100 mb-3">

      <!-- ====== show loader ====== -->
      <div class="body-section text-center" *ngIf="status === SwapStatus.in_progess">
        <img src="/assets/images/svg-icons/logo.svg" alt="loading" class="statusIcon loading">
        <div class="fw-normal fw-bold font-16 px-2 my-3 text-uppercase text-white  text-center">{{"widget.transaction_in_progress" | translate}}</div>
        <p class="fs-7 mb-0 text-white">{{"staking.stake_transction_info" | translate: {stakeAmount} }}</p>
      </div>


      <!-- ====== show confirm ====== -->

      <div class="body-section text-center" *ngIf="status === SwapStatus.confirm_pending">
        <img src="/assets/images/svg-icons/logo.svg" alt="loading" class="statusIcon loading">
        <div class="fw-semibold fs-6 my-3 text-uppercase text-black text-center">{{"misc.confirm" | translate}}
        </div>
        <p class="fs-7 mb-0 text-white">{{"staking.stake_waiting_confirm" | translate : {cryptoName } }}</p>
      </div>


      <!-- ====== show pending ====== -->

      <div class="body-section text-center" *ngIf="status === SwapStatus.approval_pending">
        <img src="/assets/images/svg-icons/logo.svg" alt="loading" class="statusIcon loading">
        <div class="fw-semibold fs-6 my-3 text-uppercase text-black text-center">{{"widget.authorise" | translate}}
        </div>
        <p class="fs-7 mb-0 text-white">{{"staking.stake_waiting_approve" | translate : {cryptoName} }}</p>
      </div>


      <!-- ====== show success/completed ====== -->

      <div class="body-section text-center" *ngIf="status === SwapStatus.complete">
        <img src="/assets/images/popup-icons/success.svg" alt="complete" class="statusIcon">
        <div class="fw-semibold fs-6 my-3 text-uppercase text-success text-center">{{"stake_transaction_success" | translate}}</div>
        <p class="fs-7 mb-3 mb-lg-4 text-black">{{"staking.stake_transction_info" | translate: {stakeAmount} }}</p>

        <div class="d-flex align-items-center justify-content-center gap-3">
          <button class="btn btn-outline-tertiary fs-7 w-80" (click)="viewUrl()">{{"widget.view_transaction" | translate}}</button>
          <button class="btn btn-outline-secondary fs-7 w-100" (click)="closeModal()">{{"misc.done" | translate}}</button>
        </div>
      </div>


      <!-- ====== show failed ====== -->

      <div class="body-section text-center" *ngIf="status === SwapStatus.failed">
        <img src="/assets/images/popup-icons/warning.svg" alt="failed" class="statusIcon">
        <div class="fw-semibold fs-6 my-3 text-uppercase text-danger text-center">{{"staking.stake_transaction_failed" |  translate}}</div>
        <p class="fs-7 mb-3 mb-lg-4 text-warning">{{"staking.stake_view_transaction_info" | translate}}</p>

        <div class="d-flex align-items-center justify-content-center gap-3">
          <button class="btn btn-outline-tertiary fs-7 w-80" (click)="viewUrl()">{{"widget.view_transaction" | translate}}</button>
          <button class="btn btn-outline-secondary fs-7 w-100" (click)="closeModal()">{{"misc.done" | translate}}</button>
        </div>
      </div>


      <!-- ====== show rejected ====== -->

      <div class="body-section text-center" *ngIf="status === SwapStatus.rejected">
        <img src="/assets/images/popup-icons/warning.svg" alt="failed" class="statusIcon">
        <div class="fw-semibold fs-6 text-uppercase text-warning my-3 text-center">{{"staking.stake_transaction_rejected" | translate}}</div>

        <div class="d-flex align-items-center justify-content-center gap-3 mt-4">
          <button class="btn btn-outline-secondary fs-7 w-100" (click)="closeModal()">{{"misc.done" | translate}}</button>
        </div>
      </div>
    </div>
  </div>

</ng-template>


<!-- ====== Buy and stake modal ====== -->
<ng-template #buy_stake>
  <div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <h4 class="modal-title pull-left text-white">{{"staking.buy_stake" | translate}}</h4>
    <svg-icon src="/assets/images/svg-icons/close-white.svg" [svgStyle]="{ 'width.px':12, 'height.px':12 }"
      class="cursor-pointer stake-close" (click)="closeModal()"></svg-icon>
  </div>
  <div class="modal-body bg-primary">
    <app-swap [isNative]="true" [isWert]="false" [isModal]="true"></app-swap>
  </div>
</ng-template>
