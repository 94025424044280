import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LanguageComponent } from './components/language/language.component';
import { StakingComponent } from './components/staking/staking.component';
import { languages } from './shared/constants/app-enums';

const routesLang: Routes = languages.map((lang) => {
  return {
    path: lang.value,
    component: LanguageComponent,
    children: [
      {
        path: '', component: HomeComponent
      },
      {
        path: 'staking', component: StakingComponent
      },
    ]
  }
});

const routes: Routes = [
  { path: "en", redirectTo: "", pathMatch: "full" },
  {
    path: '',
    component: LanguageComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'staking', component: StakingComponent},
    ]
  },
  ...routesLang,
  {  path: '**',  redirectTo: '', pathMatch: 'full'},
  {  path: '*path',  redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'ignore',
      scrollPositionRestoration: 'enabled'
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
