<div class="banner" id="home">
  <div class="container">
    <div class="bannerSec row">

      <!-- ======= Banner Left Content ======= -->

      <div class="col-12 col-md-7 leftSec align-self-center">
        <div class="banner-content ">
          <h1 class="banner-title">{{"banner.banner_text_1" | translate }}</h1>
          <h5 class="font-16 fw-regular banner-desc mt-3">{{"banner.banner_desc" | translate }}</h5>

          <div class="d-flex justify-content-center justify-content-md-start mb-3 pt-4 gap-3">
            <a class="px-4 btn btn-secondary font-16" target="_blank" href="/assets/documents/ICO_Whitepaper.pdf"><img src="/assets/images/svg-icons/whitepaper-icon.svg" alt=""><span class="ps-2">{{"misc.whitepaper" | translate }}</span></a>
            <a class="px-4 btn btn-primary font-16" target="_blank" href="/assets/documents/ICO_Audit.pdf"><img src="/assets/images/svg-icons/audit-icon.svg" alt=""><span class="ps-2">{{"misc.audit" | translate}}</span></a>
          </div>
        </div>
      </div>

      <!-- ======= Banner right Content ======= -->

      <div class="col-12 col-md-5 rightSec align-self-center">

        <div class="walletBox">

          <div class="w-100 d-flex flex-column align-items-center justify-content-start text-center">

            <p class="font-19 text-capitalize fw-bold mb-3" *ngIf="!isSoldOut">{{"widget.widget_title" | translate }}</p>
            <p class="font-19 fw-bold mb-3" *ngIf="isSoldOut">{{"widget.sold_out_stage" | translate }}</p>

            <!-- ======= counter ======= -->

            <div class="d-flex align-items-center justify-content-center w-100 gap-2 counter">
              <div class="rounded-3 time-card text-center d-flex" [ngClass]="{'shimmer': liveCounter._diff == 0}">
                <div class="value">{{liveCounter._days}}</div>
                <div class="indicator">{{"widget.days" | translate}}</div>
              </div>
              <div class="rounded-3 time-card text-center" [ngClass]="{'shimmer': liveCounter._diff == 0}">
                <div class="value">{{liveCounter._hours}}</div>
                <div class="indicator">{{"widget.hours" | translate}}</div>
              </div>
              <div class="rounded-3 time-card text-center" [ngClass]="{'shimmer': liveCounter._diff == 0}">
                <div class="value">{{liveCounter._minutes}}</div>
                <div class="indicator">{{"widget.minutes" | translate}}</div>
              </div>
              <div class="rounded-3 time-card text-center" [ngClass]="{'shimmer': liveCounter._diff == 0}">
                <div class="value">{{liveCounter._seconds}}</div>
                <div class="indicator">{{"widget.seconds" | translate}}</div>
              </div>
            </div>

            <p class="my-3 fs-7 text-center" [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}">
              <span class="text-uppercase"> {{"widget.usdt_raised" | translate}} </span>
              <span class="fw-regular">${{convertNum(usdRaised)}}</span>
              <span class="fw-regular text-light"> / ${{convertNum(maxAmount)}}</span>
            </p>

            <div class="progress" data-percent="0" [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}" *ngIf="!isSoldOut">
              <div class="bar" [style.width]="raisedPercent"></div>
              <div class="status"  [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}">{{"widget.bar_title" | translate}}</div>
            </div>


            <div class="d-flex justify-content-center align-items-center text-center mb-2 font-12  px-3 mt-3"
              [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}">
              <span class="text-uppercase">{{"widget.your_eth_claimable" | translate :{tokenName} }}</span>
              <span class=" text-light"> = {{convertNum((userStaked + userClaimable))}}</span>
              <img src="/assets/images/svg-icons/info-icon.svg" class="img-fluid ms-2 cursor-pointer" (click)="yourPurchasedInfo()" alt="">
            </div>

            <div class="d-flex justify-content-center align-items-center text-center font-12 px-3 mb-3"
              [ngClass]="[(!usdRaised || usdRaised <=0) ? 'shimmer' :'']">
              <span class="text-uppercase">{{"widget.your_bsc_claimable" | translate :{tokenName} }}</span>
              <span class="fw-regular text-light"> = {{convertNum(userEthClaimable)}}</span>
              <img src="/assets/images/svg-icons/info-icon.svg" class="img-fluid ms-2 cursor-pointer" (click)="stakeInfo()" alt="">
            </div>

            <button type="button" class="btn btn-sm btn-secondary mb-4 mt-2 mx-auto"
              (click)="referAndEarn()" *ngIf="walletAddress && referUrl != ''">{{"widget.refer_and_earn" | translate}}
            </button>
          </div>

          <div class="swapArea" *ngIf="!isSoldOut"
          [ngClass]="{'cover': status === SwapStatus.in_progess || status === SwapStatus.confirm_pending || status === SwapStatus.approval_pending}">
          <p class="text-center mb-3 font-14 dashTitle" [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}">1
            {{tokenName}} = ${{salePrice}}</p>

          <div class="tab-container gap-2">
            <button class="btn text-uppercase gap-2 w-100" [ngClass]="{'selected': swapMode ==='native'}" (click)="openSwap(true)">
              <img src="/assets/images/svg-icons/{{modeName}}.svg" height="26" alt="" />
              <span class="fw-regular">{{modeName}}</span>
            </button>

            <button class="btn text-uppercase gap-2 w-100" [ngClass]="{'selected': swapMode ==='usdt'}" (click)="openSwap(false)">
              <img src="/assets/images/svg-icons/usdt.svg" height="26" alt="" />
              <span class="fw-semibold">USDT</span>
            </button>

            <button class="btn text-uppercase gap-2 w-100" [ngClass]="{'selected': swapMode ==='card'}" (click)="openSwap(false, true)">
              <img src="/assets/images/svg-icons/card.svg" height="26" alt="" class="float-start" />
              <span class="fw-semibold">{{"widget.card" | translate }}</span>
            </button>
          </div>

          <app-swap [isNative]="isNative" [isWert]="isWert" #swap></app-swap>

          <div class="d-flex align-items-center justify-content-center gap-2 mt-4" *ngIf="!walletAddress">
            <button class="btn btn-secondary w-100" (click)="connectModal()">
              <span>{{"misc.connect_wallet" | translate}}</span>
            </button>

            <button class="btn btn-secondary w-100 px-2" (click)="changeChain(modeName === 'ETH' ? 'BSC' : 'ETH')">
              <img src="/assets/images/svg-icons/{{modeName === 'ETH' ? 'BNB' : 'ETH'}}.svg" height="18" alt=""  class="me-2" />
              <span>{{'widget.switch_to' | translate}} {{modeName === 'ETH' ? 'BNB' : 'ETH'}}</span>
            </button>
          </div>

          <div class="mt-3">
            <p class="font-13 text-center mb-1">
              <a *ngIf="modeName=='ETH' && walletAddress" class="text-decoration-underline cursor-pointer text-light"
                href="https://widget.wert.io/default/widget/?commodity=ETH%3AEthereum" target="_blank">
                {{"widget.not_enough_eth" | translate}}
              </a>
            </p>

            <!--- dont have wallet-->
            <a href="<presale-sing-url-need-to-be-added>" target="_blank" class="m-2 d-block d-md-none text-decoration-underline text-center mx-auto text-white fw-semibold font-14" *ngIf="!walletAddress">
                  {{"widget.no_wallet_click_here" | translate}}
            </a>

            <div class="m-2 d-none d-md-block cursor-pointer mx-auto text-center" (click)="connectModal(true)" *ngIf="!walletAddress">
                <span class="text-white fw-semibold font-14 text-decoration-underline">{{"widget.no_wallet_click_here" | translate}}</span>
            </div>
            <!--- dont have wallet-->

            <p class="font-14 text-center fw-regular mb-0">
                <span>{{"widget.powered_by" | translate}}</span>
              <a href="https://web3paymentsolutions.io" target="_blank">
                <img src="/assets/images/svg-icons/W3P_White.svg" alt="" class="poweredByIcon text-tertiary">
              </a>
            </p>
          </div>

        </div>
        </div>
      </div>

    </div>
  </div>
</div>
  
