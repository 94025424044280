import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { PopupService } from '../../services/popup.service';



@Component({
  selector: 'app-refer',
  templateUrl: './refer.component.html',
  styleUrls: ['./refer.component.scss']
})

export class ReferComponent {

  url = ''
  constructor(
    public modalRef: BsModalRef,
    private popupService: PopupService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  copy() {
    this.popupService.messsageAlert('success', this.translateService.instant("misc.successCopy"));
  }
}
