import { InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken<Window>('WindowToken', {
  factory: () => {
    if (typeof window !== 'undefined') {
      return window;
    }
    // Fallback: Create a new Window object (does this work? 🤔)
    return new Window();
  },
});
