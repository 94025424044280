import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { isPlatformBrowser, Location } from '@angular/common';

import { languages } from '../../shared/constants/app-enums';
import { EventService } from '../../services/event.service';
import { WalletConnectService } from '../../services/wallet-connect.service';
import { ConnectComponent } from '../../modals/connect/connect.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {

  @ViewChild('navigation') navigation!: ElementRef;
  isMobile: boolean = false;
  isScrolled: boolean = false;
  showMenu: boolean = false;
  openMenu: boolean = false;
  modalRef?: BsModalRef;
  hashKey: string = '';
  windowSize: number = 0;
  currentLanguage: any;
  languagesList: Array<any> = languages;
  selectedLanguage: any;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isScrolled = window.pageYOffset >= 50;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowSize = window.innerWidth;
    this.isMobile = this.windowSize <= 1024 ? true : false;
  }

  navigations: Array<any> = [
    {
      routeName: 'about',
      navName: 'top_navigation.nav_about',
    },
    {
      routeName: 'roadmap',
      navName: 'top_navigation.nav_roadmap',
    },
    {
      routeName: 'how-to-buy',
      navName: 'top_navigation.nav_howtobuy',
    },
    {
      routeName: 'faq',
      navName: 'top_navigation.nav_faq',
    },
  ];

  social = [
    {
      img:"assets/images/svg-icons/social-icons/telegram.svg",
      alt:"",
      link:""
    },
    {
      img:"assets/images/svg-icons/social-icons/twitter.svg",
      alt:"",
      link:""
    },
  ]



  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private location: Location,
    private eventService: EventService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private walletConnectService: WalletConnectService,
  ) {
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      // console.log('LangChangeEvent called in header', params.lang);
      this.currentLanguage = params.lang;
      this.selectedLanguage = this.languagesList.filter((x) => x.value === this.currentLanguage)[0];
      if (isPlatformBrowser(this._platformId)) localStorage.setItem('lang', this.currentLanguage);
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.windowSize = window.innerWidth;
      this.isMobile = this.windowSize <= 1024 ? true : false;

      if(!this.currentLanguage){
        this.currentLanguage = localStorage.getItem('lang') || 'en';
        this.selectedLanguage = this.languagesList.filter((x) => x.value === this.currentLanguage)[0];
      }

      if (window.location.hash) {
        setTimeout(() => {
          this.hashKey = window.location.hash.substring( 1,window.location.hash.length);
          this.addActive(this.navigation.nativeElement, this.hashKey);
        }, 2000);
      }
    }
  }

  changeLanguage(requestLang: any): void {
    if(this.currentLanguage !== requestLang){
      this.currentLanguage = requestLang;
      this.translate.use(this.currentLanguage);
      this.eventService.setLang(this.currentLanguage);
      this.selectedLanguage = this.languagesList.filter((x) => x.value === this.currentLanguage)[0];
      this.replaceURL();
    }
  }

  replaceURL(): void {
    const current_url = this.location.path().split('/');
    current_url.shift();
    current_url.splice(0, 1, this.currentLanguage);
    const new_url = this.hashKey ? current_url.join('/') + `#${this.hashKey}` : current_url.join('/');
    this.location.go('/' + new_url);
    // this.location.replaceState('/' + new_url);
    // window.location.hash = this.hashKey;
  }

  navigateToSection(section: string) {
    if (isPlatformBrowser(this._platformId)) {
      if ( window.location.pathname.includes('dashboard') && section && section != '') {
        window.location.hash = this.hashKey;
        window.location.pathname = window.location.pathname.replace('staking', '' );
      }
    }
  }

  addActive(event: any, section: string) {
    this.hashKey = section;
    const target: any = event.currentTarget ? event.currentTarget : event;
    let linkList = target.parentNode.querySelectorAll('a');
    linkList.forEach((link: any) => {
      if (link === event.target || link.title === this.hashKey) {
        link.classList.add('active');
      } else {
        link.classList.remove('active');
      }
    });
    if (section) this.navigateToSection(this.hashKey);
  }

  get walletAddress(): any {
    return this.walletConnectService.walletAddress;
  }

  showWidget(section: string): void {
    if (window.location.pathname.includes('dashboard')) {
      window.scroll({ top: 0 });
      this.connectModal();
    } else {
      this.eventService.showBuyWidget(section);
    }
  }

  connectModal(): void {
    this.modalRef = this.modalService.show(ConnectComponent, {
      class: 'modal-xs modal-dialog-centered',
      backdrop: 'static',
    });
  }

  disconnect(): void {
    this.walletConnectService.disConnectWallet();
  }

  openCloseMenu(): void {
    if (this.showMenu) {
      this.openMenu = !this.openMenu;
      setTimeout(() => (this.showMenu = !this.showMenu), 600);
    } else {
      this.showMenu = !this.showMenu;
      setTimeout(() => (this.openMenu = !this.openMenu), 300);
    }
  }
}
