<section class="footer" id="footer">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-3">
                <img src="assets/images/svg-icons/logo.svg" alt="" class="logo pb-lg-3 pb-4 mx-auto d-block ms-lg-0" loading="lazy"/>
                <p class="font-14 d-none d-md-block">{{"footer_navigation.disclaimer_desc" | translate}}<p>
            </div>
            <div class="col-6 col-lg-3">
                <h2 class="font-20 ms-3 d-none d-md-block">{{"footer_navigation.title_1" | translate}}</h2>
                <div class="section-line ms-3 my-3 d-none d-md-block"></div>
                <ul *ngFor="let item of links" type="none" class="text-left">
                    <li class="font-14">
                        <a href="javascript:void(0);" (click)="navigateToSection(item.routeName)" title="{{item.routeName}}" class="" >{{item.title | translate}}</a>
                    </li>
                </ul>
            </div>
            <div class="col-6 col-lg-3">
                <h2 class="font-20 mb-3 d-none d-md-block ms-md-3 ms-lg-0">{{"footer_navigation.title_2" | translate}}</h2>
                <div class="section-line my-3 d-none d-md-block ms-md-3 ms-lg-0"></div>
                <a *ngFor="let item of items" href="{{item.link}}" class="text-left ">
                    <li class="font-14 py-2 ms-3 ms-lg-0" type="none">{{item.title | translate }}</li>
                </a>
            </div>
            <div class="col-lg-3">
                <div class="line my-3" *ngIf="isMobile"></div>
                <h2 class="font-20 d-none d-md-block ms-md-3 ms-lg-0">{{"footer_navigation.title_3" | translate}}</h2>
                <div class="section-line my-3 d-none d-md-block ms-md-3 ms-lg-0"></div>
                <div class="copyright">
                    <div class="my-3 px-3 px-lg-0">
                        <a *ngFor="let item of social" href="{{item.link}}" class="link pe-2" target="_blank">
                            <img src="{{item.img}}" alt="{{item.alt}}" loading="lazy">
                        </a>
                    </div>
                    <p class="d-block d-md-none font-14  px-3">{{"footer_navigation.address" | translate}}</p>
                    <p class="d-block d-md-none font-14  px-3">{{"footer_navigation.disclaimer" | translate}}</p>
                    <p class=" font-14 px-3 px-lg-0">{{"footer_navigation.copyright" | translate}}</p>
                </div>
            </div>
        </div>
    </div>
  </section>