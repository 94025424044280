// Start of Event Service code
import { BehaviorSubject, from, interval, Observable, Subject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { filter, map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ConnectComponent } from '../modals/connect/connect.component'
import { WINDOW } from './window';



// Used to store key value pairs
interface Event {
  key: string;
  value: any;
}

@Injectable({
  providedIn: 'root',
})

export class EventService {
  public isConnected: boolean = false;
  protected eventsSubject = new Subject<Event>();
  private sessionCounter: any;
  public count: number = 0;
  private endValue: number = 60 * 15;
  private langUpdated = new Subject<string>();
  private netWork = new BehaviorSubject<boolean>(true);
  public selectedLanguage = "en";
  public currentLanguage: any;
  ignoreList: Array<any> = ['api.moonpay', 'ramp_transaction', 'coingecko'];
  private daysCounter = new BehaviorSubject<any>({_days: 0, _hours: 0, _minutes: 0, _seconds: 0, _diff: 0});
  private stopper = new Subject();
  private counter:any;

  constructor(
    @Inject(WINDOW) private window: Window,
    private translate: TranslateService,
    private modalService: BsModalService,
  ) {}

  /*Method is responsible for Broadcast Event */
  public broadcastEvent(key: string, value: any): void {
    this.eventsSubject.next({ key, value });
  }

  /* Method is responsible for Get Event*/
  public getEvent(key: string): Observable<any> {
    return this.eventsSubject.asObservable().pipe(
      filter(e => e.key === key),
      map(e => e.value)
    );
  }

  checkList(url: string): boolean {
    const matched = this.ignoreList.filter(element => url.includes(element));
    return matched.length > 0 ? false : true;
  }


  setNetwork(): void {
    this.netWork.next(true);
  }

  getNetwork(): Observable<boolean> {
    return this.netWork.asObservable();
  }

  getLang(): Observable<string> {
    return this.langUpdated.asObservable();
  }

  setLang(lang: string) {
    localStorage.setItem('currentlang', lang)
    this.currentLanguage = lang;
    this.langUpdated.next(lang ? lang : 'en')
  }

  resetCounter() {
    if(this.counter) this.counter.unsubscribe();
    this.stopper = new Subject();
    console.log('resetCounter called')
  }

  setCounter(endDate: any){
    console.log('setCounter =', endDate);
    let _diff: any;
    this.counter = interval(1000).pipe(
      takeUntil(this.stopper),
      map((x) => {
        _diff = endDate - Date.parse(new Date().toString());
      })).subscribe((x) => {
        if(_diff <= 0) {
          this.broadcastEvent('PRESALE-END', true);
          this.stopper.next(false)
        }else{
          const object: any = {
            _days: this.getDays(_diff) < 10 ? ('0' + this.getDays(_diff) ) : this.getDays(_diff),
            _hours: this.getHours(_diff) < 10 ? ('0' + this.getHours(_diff) ) : this.getHours(_diff),
            _minutes: this.getMinutes(_diff) < 10 ? ('0' + this.getMinutes(_diff) ) : this.getMinutes(_diff),
            _seconds: this.getSeconds(_diff) < 10 ? ('0' + this.getSeconds(_diff) ) : this.getSeconds(_diff),
            _diff
          }
          this.daysCounter.next(object)
        }
      });
    }

  getCounter(): Observable<any> {
    return this.daysCounter.asObservable();
  }

  getDays(t: number) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t: number) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t: number) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t: number) {
    return Math.floor((t / 1000) % 60);
  }

  scrollToFn(section: string) {
    if (this.window){
      window.location.hash = section;
    //   console.log(window.location.hash, "window.location.hash")
    //   window.location.pathname = '/' + this.currentLanguage;
    }
  }

  showBuyWidget(scrollTo: string = ''): void {
    if(scrollTo && !this.isConnected){
      this.scrollToFn(scrollTo);
      setTimeout(()=>{
        this.modalService.show(ConnectComponent, {
          class: 'modal-min modal-dialog-centered',
          backdrop: 'static'
        });
      },1000);
    }else{
      this.scrollToFn(scrollTo);
    }
  }
}
