import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, PLATFORM_ID } from '@angular/core'
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {

  windowSize: number = 0;
  isMobile:boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowSize = window.innerWidth;
    this.isMobile = this.windowSize <= 1024;
  }

  items: any = [
    {
      heading: 'faq.faq_1',
      content: 'faq.faq_cont_1',
    },
    {
      heading: 'faq.faq_2',
      content: 'faq.faq_cont_2',
    },
    {
      heading: 'faq.faq_3',
      content: 'faq.faq_cont_3',
    },
    {
      heading: 'faq.faq_4',
      content: 'faq.faq_cont_4',
    },
  ]

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef   
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.windowSize = window.innerWidth;
      this.isMobile = this.windowSize <= 1024;
      this.cdRef.detectChanges();
    }
  }
}
