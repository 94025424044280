<section class="feature-wrapper">
  <h3 class="section-title text-center mt-2">{{"feature.title" | translate}}</h3>
  <div class="container-fluid feature py-0">
    <owl-carousel-o [options]="slideOption" dir="ltr" class="d-flex" *ngIf="isRender">
      <ng-template carouselSlide *ngFor="let item of items">
        <img [src]="item.img" class="img-fluid mx-auto featured-img p-2" alt="" />
      </ng-template>
    </owl-carousel-o>
  </div>
</section>
