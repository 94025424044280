<div class="modal-header">
  <h4 class="modal-title text-center">{{(isStake ? 'staking.claim_stake_title' : (isWithdraw ? 'staking.withdraw_title' :  'widget.claim_title')) | translate}}</h4>
</div>

<div class="modal-body text-center" *ngIf="status != SwapStatus.complete">
  <p class="text-white text-center mb-3 font-sm-15">{{(isStake ? 'staking.claim_stale_help' : 'widget.claim_help') | translate}}</p>
  <div class="footer">
    <button type="button" class="btn btn-primary fs-7 w-50" (click)="modalRef.hide()">{{"misc.ok" | translate}}</button>
  </div>
</div>

<div class="modal-body text-center" *ngIf="status == SwapStatus.complete">
  <img src="/assets/images/popup-icons/success.svg" alt="complete" class="statusIcon">
  <div class="text-secondary text-center mb-3 font-sm-15">{{"staking.stake_claim_success" | translate}}</div>
  <a type="button" href="{{lang}}/staking" class="btn btn-outline-tertiary fs-7 w-auto" (click)="modalRef.hide()">{{"misc.close" | translate}}</a>
</div>
