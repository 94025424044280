import { http, createConfig, createStorage, injected } from '@wagmi/core';
import { coinbaseWallet, walletConnect } from '@wagmi/connectors';
import { bsc, bscTestnet, mainnet, sepolia,} from '@wagmi/core/chains';
import { environment } from '../../environments/environment';



/**
 * Wagmi Core chain, provider and connector configuration.
 * If production mode then mainnet and paid RPC provider else
 * testnet and publicProvider
 */

const currentChain: any = environment.production
  ? [mainnet, bsc]
  : [sepolia, bscTestnet];



/**
 * Setup of Metamask connector which will work
 * with metamask browser extension
 */

export const metaMaskConnector = injected({ target: 'metaMask' });


/**
 * Setup of wallet connector v2 which will work
 * with mobile app like trustwallet, alphawallet app.
 * the projectId is mandatory and can be generated from
 * https://cloud.walletconnect.com/sign-in
 */

export const walletConnector = walletConnect({
  projectId: environment.walletConnectId,
  qrModalOptions: {
    explorerRecommendedWalletIds: [
      'fe68cea63541aa53ce020de7398968566dfe8f3725663a564cac89490247ed49',
      'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
      '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    ],
  },
});

export const walletConnectorBW = walletConnect({
  projectId: environment.walletConnectId,
  qrModalOptions: {
    explorerRecommendedWalletIds: [
      'fe68cea63541aa53ce020de7398968566dfe8f3725663a564cac89490247ed49',
    ],
  },
});

export const coinbaseConnector = coinbaseWallet({
    appName: 'ICO Boilerplate',
    appLogoUrl: 'https://d1jhu0l0qt26ae.cloudfront.net/assets/images/svg-icons/token.svg',
})

export const uriConnector = walletConnect({
  showQrModal: false,
  projectId: environment.walletConnectId,
  qrModalOptions: {
    explorerRecommendedWalletIds: [
      'fe68cea63541aa53ce020de7398968566dfe8f3725663a564cac89490247ed49',
    ],
  },
});


export const appConnectors = [
  metaMaskConnector,
  walletConnector,
  walletConnectorBW,
  uriConnector,
  coinbaseConnector,
];

export const config = createConfig({
  chains: currentChain,
  syncConnectedChain: true,
  storage: createStorage({ storage: typeof window !== 'undefined' ? window.localStorage : undefined }),
  connectors: appConnectors,
  ssr: true,
  // multiInjectedProviderDiscovery: false,
  transports: {
    [environment.eth.chainIdInt]: environment.production ? http(environment.eth.rpcURL) : http('https://ethereum-sepolia-rpc.publicnode.com'),
    [environment.bsc.chainIdInt]: environment.production ? http(environment.bsc.rpcURL) : http('https://bsc-testnet-rpc.publicnode.com'),
  },
});

