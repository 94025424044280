import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { v4 as uuidv4 } from "uuid";
import WertWidget from '@wert-io/widget-initializer';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { WalletConnectService } from '../../services/wallet-connect.service';
import { SwapStatus } from '../../shared/constants/app-enums';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-wert',
  templateUrl: './wert.component.html',
  styleUrls: ['./wert.component.scss']
})

export class WertComponent {

  token: string = environment.tokenName;
  baseToken: any;
  swapToken: any;
  isStake = false;
  walletAddress: any;
  wertWidget: any;
  wertOptions: any = {
    partner_id: environment.partnerId,
    origin: environment.wertOrigin,
    click_id: uuidv4(), // must be unique for any request
    lang: 'en',
    theme: 'light',
    currency: 'USD',
    commodity: 'ETH',
    network: environment.production ? 'ethereum' : 'sepolia',
    extra: {
      item_info: {
        name: "$TALC",
        image_url: "/assets/images/svg-icons/logo.svg"
      }
    }
  }
  SwapStatus = SwapStatus;
  mountError: boolean = false;
  wertIframe: any;
  closeBtn: any;
  currentStep: number = 0;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    public modalRef: BsModalRef,
    private http: HttpClient,
    private walletConnect: WalletConnectService
  ) { }

  async showWidget() {
    this.currentStep = 1;
    const ethValue = await this.walletConnect.getDynamicAmountWert(this.baseToken);
    let data = {
      address: this.walletAddress,
      commodity: 'ETH',
      network: environment.production ? 'ethereum' : 'sepolia',
      commodity_amount: +((ethValue * 1.02).toFixed(8)),
      sc_address: environment.eth.presaleAddress,
      sc_input_data: await this.walletConnect.getABI(this.baseToken, this.isStake),
    };

    if (isPlatformBrowser(this._platformId)) {
      this.http.post(environment.wertUrl, data).subscribe((res: any) => {
        const signedData = res.data;
        let isDashFXSent = false;

        const wertObj = { ...signedData, ...this.wertOptions, }
        console.log('wertOptions =', wertObj);

        this.wertWidget = new WertWidget({
          ...signedData,
          ...this.wertOptions,
          listeners: {
            loaded: () => {
              console.log('widget loaded:');
              this.walletConnect.swapStatus = SwapStatus.confirm_pending;
              this.modalRef.hide();
            },
            close: () => {
              console.log('widget closed:');
              this.walletConnect.swapStatus = SwapStatus.rejected;
            },
            position: (data: any) => console.log('step:', data.step),
            'payment-status': (status: any) => {
              console.log('payment-status:', status);

              if (status.status == 'progress' || status.status == 'pending') {
                this.walletConnect.swapStatus = SwapStatus.in_progess;
              }

              if ((status.status == 'progress' || status.status == 'success') && !isDashFXSent) {
                this.walletConnect.sendDashFx('eth', this.baseToken, +((ethValue).toFixed(8)));
                isDashFXSent = true;
              }

              if (status.status == 'success') {
                this.walletConnect.checkTransaction(status.tx_id, 'usd', this.baseToken, this.swapToken);   // checking transaction status
                this.wertWidget.removeEventListeners();
                this.wertWidget.close();
              }

              if (status.status == 'canceled') {
                this.walletConnect.swapStatus = SwapStatus.rejected;
              }
            },
            error: (err: any) => {
              console.log('error:', err);
              this.walletConnect.swapStatus = SwapStatus.failed;
            },
          },
        });
        this.wertWidget.open();
      },
        (err: any) => {
          console.log('wert error response', err);
          this.mountError = true;
          this.walletConnect.swapStatus = SwapStatus.not_started;
        })
    }
  }

}
