<div class="bestwallet">
  <div class="content">    
    <div class="d-flex justify-content-between align-items-center">
      <div></div>
      <svg-icon src="/assets/images/svg-icons/close-white.svg" [svgStyle]="{ 'width.px':14, 'height.px':14 }"
        class="cursor-pointer" (click)="modalRef.hide()"></svg-icon>
    </div>
    <div class="content_holder d-block d-md-none">
      <div class="info_holder">
        <img src="/assets/images/bestwallet_modal/parasuit.svg" alt="qr" loading="lazy" class="top-img">
        <h2 class="text-white" >{{"bw_modal.purchase_success" | translate}}</h2>
        <p>{{"bw_modal.mobile_info" | translate :{walletAddress} }}</p>
        <a href="<post-presale-sing-url-need-to-be-added>" target="_blank" type="button"
          class="btn btn-bw border-0 rounded-1 w-75 mb-2 mt-4" >{{"bw_modal.download_bw" | translate}}</a>
        <button type="button" class="btn btn-outline-white rounded-1 w-75" (click)="modalRef.hide()"
          >{{"bw_modal.already_have" | translate}}</button>
      </div>
    </div>
    <div class="content_holder d-none d-md-block">
      <div class="info_holder">
        <img src="/assets/images/bestwallet_modal/header_ticker.svg" alt="qr" loading="lazy" class="top-img">
        <h2 class="text-white">{{"bw_modal.purchase_success" | translate}}</h2>
        <p >{{"bw_modal.desktop_info_1" | translate}}</p>
        <p class="dashP" >{{"bw_modal.desktop_info_2" | translate}}</p>
        <p >{{"bw_modal.desktop_info_3" | translate}}</p>
      </div>

      <div class="d-flex align-items-center justify-content-around mt-2 gap-3">
        <div class="d-flex flex-column align-items-center justify-content-center block">
          <div class="no">1</div>
          <div class="qr-cont">
            <img src="/assets/images/bestwallet_modal/post_presale_qrcode.svg" alt="qr" loading="lazy" class="qr-img">
          </div>
          <h6 >{{"bw_modal.block_1_title" | translate}}</h6>
          <p >{{"bw_modal.block_1_desc" | translate}}</p>
        </div>

        <div class="d-flex flex-column align-items-center justify-content-center block">
          <div class="no">2</div>
          <img src="/assets/images/bestwallet_modal/icon_collage.svg" alt="qr" loading="lazy" class="blockPic">
          <h6 >{{"bw_modal.block_2_title" | translate}}</h6>
          <p >{{"bw_modal.block_2_desc" | translate :{walletAddress} }}</p>
        </div>

        <div class="d-flex flex-column align-items-center justify-content-center block">
          <div class="no">3</div>
          <img src="/assets/images/bestwallet_modal/parasuit.svg" alt="qr" loading="lazy" class="blockPic">
          <h6>{{"bw_modal.block_3_title" | translate}}</h6>
          <p>{{"bw_modal.block_3_desc" | translate}}</p>
        </div>
      </div>
    </div>    
  </div>
</div>